import React, {useEffect} from 'react';
import {navigate} from 'gatsby';
import './styles.scss';

const Pricing = () => {
  useEffect(() => {
    navigate('/pricing_promo');
  }, []);

  return <div className="content-wrapper"></div>;
};

export default Pricing;
